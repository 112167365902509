#immo-directsearch {
  .immo-directsearch-box {
    color: $white;
    text-align: center;
    /*background: alphaHls($ci-secondary,0.9);*/
    background: rgb(255,255,255,0.3);
    border-radius: 10px;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;


    .immo-filter {
      margin-top: 0;
    }
    .select-immo-type,
    .select-immo-objects {
      width: 100%;
      padding: 0 10px;
      font-family: 'Roboto';
      label {
        display: none;
      }
      .immo-object {
        border: 1px solid rgb(190 175 140);
        box-shadow: 0px 0px 6px rgb(156 144 115);
        width: 100%;
        padding: 5px 10px;
        line-height:30px;
        height: 42px;
        border-radius: 10px;
        option::before {
          color: black !important;
          position: absolute;
          top: 0;
          right: 0;
          font-family: "Font Awesome 6 Free";
          content: "f0d7";
        }
      }
    }

    .submit-button {
      position: relative;

      input {
        @extend .btn-orange;
        width: 100%;
        text-align: center;
      }

    }
  }
}

#stage {
  #immo-directsearch {
    position: absolute;
    top: 150px;
    left: 20px;
    right: 20px;
    padding: 0;
    z-index: 100;
  }
}

@include media-breakpoint-down(md) {
  #immo-directsearch {
    .immo-directsearch-box {
      h2 {
        padding: 0 0 15px;
      }

      .select-immo-type,
      .select-immo-objects {
        width: 100%;
        padding: 0 0 15px;
        min-height: 45px;
        .dropdown-toggle {
          line-height: 24px;
          padding: 2px 25px 2px 10px;
        }
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  #stage #immo-directsearch {
    position: absolute;
    top: 20%;
    left: auto;
    right: auto;
    width: 100%;
    padding: 0;
    z-index: 100;

    .immo-directsearch-box {
      width: 850px;
    }
  }
  #hero-content {
    #immo-directsearch {
      width: 970px;
      padding: 0 15px;
      .immo-directsearch-box {
        right: 35px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #hero-content {
    #immo-directsearch {
      width: 1170px;
    }
  }
}